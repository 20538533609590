import Vue from 'vue';
import Vuex from 'vuex';

import axios from 'axios';

Vue.use(Vuex);

// var base = 'https://www.rocherenovaties.com/';
var base = process.env.NODE_ENV === 'production' ? '/' : 'https://www.rocherenovaties.com/';

export default new Vuex.Store({
	state: {
		base: base,
		contactData: {},
		galleries: [],
		pages: [],
		projects: [],
		structure: [],
	},
	
	
	getters: {
		base: state => {
			return state.base;
		},
		
		
		contactData: state => {
			return state.contactData;
		},
		
		
		galleries: state => {
			return state.galleries;
		},
		
		
		pages: state => {
			return state.pages;
		},
		
		
		projects: state => {
			return state.projects;
		},
		
		
		specialisms: state => {
			let sp = [];
			
			for (let i = 0; i < state.pages.length; i++)
			{
				let p = state.pages[i];
				
				if (p.template == 2)
				{
					sp.push(p);
				}
			}
			
			return sp;
		},
		
		
		structure: state => {
			return state.structure;
		},
	},
	
	
	mutations: {
		setContactData (state, value)
		{
			state.contactData = value;
		},
		
		
		setPages (state, value)
		{
			state.pages = value;
		},
		
		
		setStructure (state, value)
		{
			state.structure = value;
		},
		
		
		updateGalleries (state, value)
		{
			let id = false;
			
			// See if the path already has a gallery stored
			for (let i = 0; i < state.galleries.length; i++)
			{
				let g = state.galleries[i];
  
				// If the paths match, set the ID and break the loop
				if (g.path == value.path)
				{
					id = i;
					break;
				}
			}
  
			// If a match was found, set the new images list
			if (id !== false)
			{
				state.galleries[id] = value;
			}
			// Otherwise, add the new gallery
			else
			{
				state.galleries.push(value);
			}
		},
  
  
		updateProjects (state, projects)
		{
			for (let i = 0; i < projects.length; i++)
			{
				let id = false;
				let value = projects[i];
  
				// See if the path already has a gallery stored
				for (let j = 0; j < state.projects.length; j++)
				{
					let p = state.projects[j];
  
					// If the paths match, set the ID and break the loop
					if (p.path == value.path)
					{
						id = j;
						break;
					}
				}
  
				// If a match was found, set the new images list
				if (id !== false)
				{
					state.projects[id] = value;
				}
				// Otherwise, add the new gallery
				else
				{
					state.projects.push(value);
				}
			}
		},
	},
  
  
	actions: {
		getContactData: (context) => {
			axios.get(base + 'api/contact.json')
			.then(response => {
				context.commit('setContactData', response.data);
			});
		},
  
  
		getGallery: ({state}, payload) => {
			let gallery = false;
  
			for (let i = 0; i < state.galleries.length; i++)
			{
				let g = state.pages[i];
  
				if (payload == g.path)
				{
					gallery = g.images;
				}
			}
  
			return gallery;
		},
  
  
		getPage: ({state}, payload) => {
			for (let i = 0; i < state.pages.length; i++)
			{
				let p = state.pages[i];
  
				if ([payload + '.json', payload + '/'].indexOf(p.path) >= 0) return p;
			}
  
			return false;
		},
  
  
		getPhotos: (context, payload) => {
			let gallery = false;
  
			return new Promise(function(resolve) {
				axios.get(base + 'api/photos.json', { params: payload.params })
				.then(response => {
					gallery = {
						path: payload.path,
						images: response.data
					};
  
					context.commit('updateGalleries', gallery);
  
					resolve(gallery);
				});
			});
		},
  
  
		getProjects: (context, payload) => {
			return new Promise(function(resolve) {
				axios.get(base + 'api/projects.json', { params: payload })
				.then(response => {
					context.commit('updateProjects', response.data);
  
					resolve(response.data);
				});
			});
		},
  
  
		getStructure: (context, payload) => {
			return axios.get(base + 'api/structure.json', { params: payload })
			.then(response => {
				context.commit('setStructure', response.data);
			});
		},
  
  
		loadPages: (context, payload) => {
			axios.get(base + 'api/pages.json', { params: payload })
			.then(response => {
				context.commit('setPages', response.data);
			})
			.catch(function (error)
			{
				console.log(error);
			});
		},
	},
  });
