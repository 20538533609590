<template>
	<div>
		<h2>
			Meer weten?
		</h2>

		<ul class="form">
			<li v-for="(item, id) in contact" :key="id">
				<textarea v-if="item.type == 'textarea'" :placeholder="item.placeholder" v-model="item.value" />
				<input v-else :type="item.type" :placeholder="item.placeholder" v-model="item.value" />
			</li>
			<li>
				<transition name="fade" mode="out-in">
					<alert-box v-if="error !== false" :alert="error" @close="error = false"/>
				</transition>

				<button @click.prevent="submitContact" :disabled="buttonDisabled">
					Verzenden
				</button>
			</li>
		</ul>
	</div>
</template>

<script>
import axios from 'axios';

import AlertBox from './AlertBox.vue';
import { mapGetters } from 'vuex';

var vm;

export default {
	components: {
		AlertBox,
	},


	data()
	{
		return {
			contact: {
				name: {
					placeholder: 'Uw naam',
					required: true,
					type: 'text',
					value: '',
				},
				email: {
					placeholder: 'E-mailadres',
					required: true,
					type: 'email',
					value: '',
				},
				message: {
					placeholder: 'Vraag / opmerking',
					required: true,
					type: 'textarea',
					value: '',
				},
			},
			error: false,
		};
	},


	computed: {
        ...mapGetters([
            'base',
        ]),


		buttonDisabled()
		{
			let disabled = false;

			for (let i = 0; i < Object.keys(vm.contact).length; i++)
			{
				let field = vm.contact[Object.keys(vm.contact)[i]];

				// Only check required fields for values
				if (field.required && field.value.length == 0)
				{
					disabled = true;
					break;
				}
				// Run some extra checks for a types
				else if (field.required && field.type === 'email' && !vm.validEmail(field.value))
				{
					disabled = true;
					break;
				}
			}

			return disabled;
		},
	},


	methods: {
		submitContact()
		{
			let valid = true;

			for (let i = 0; i < Object.keys(vm.contact).length; i++)
			{
				let prop = Object.keys(vm.contact)[i];
				let field = vm.contact[prop];

				if (Object.prototype.hasOwnProperty.call(field, 'required') && field.required && field.value.length === 0)
				{
					valid = false;
					break;
				}
			}

			if (valid)
			{
				let post = {
					data: vm.contact,
					action: 'contact',
				};

				axios.post(vm.base + 'api/forms/', post)
				.then((response) => {
					console.log(response.data);
				});
			}
			else
			{
				vm.error = {
					customClass: 'danger',
					message: 'Niet alle verplichte velden zijn ingevuld. Controleer a.u.b. het formulier en probeer het opnieuw.',
				};
			}
		},


		validEmail(input)
		{
			var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(input);
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
